import React, { useState } from 'react'
import FieldSet from '../../components/Form/FieldSet'
import Form from '../../components/Form/Form'
import HiddenInput from '../../components/Form/Inputs/HiddenInput'
import SelectInput from '../../components/Form/Inputs/SelectInput'
import SwitchInput from '../../components/Form/SwitchInput'
import { formatPhoneNumberWithMask } from '../../utils/formatPhoneNumber'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button/Button'
import { resetOtpSecret } from '../../services/authenticatorApi'
import { Dialog, Flex } from '@radix-ui/themes'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../contexts/AuthContext'

export default function UserForm({ data, isLoading, submitRef, merchantId, onSubmit, onError }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = (data) => {
    !!onSubmit && onSubmit(data)
  }
  const handleRemove2fa = async () => {
    await resetOtpSecret(data.user.id)
    setIsModalOpen(false)
  }

  const { t } = useTranslation()
  const auth = useAuth()
  const MERCHANT_ROLES = [
    { id: 'merchant_admin', value: t('UserRolesMerchantAdmin') },
    { id: 'merchant_finance', value: t('UserRolesMerchantFinance') },
    { id: 'merchant_employee', value: t('UserRolesMerchantOperator') }
  ]

  const handleError = (error) => {
    !!onError && onError(data)
  }

  const sanitizedUser = { ...data?.user, mobile_phone: formatPhoneNumberWithMask(data?.user?.mobile_phone) }

  return (
    <div>
      <div className="">
        <Form
          defaultValues={sanitizedUser}
          onSubmit={handleSubmit}
          onError={handleError}
          isLoading={isLoading}
          submitRef={submitRef}
          className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
          noValidate
        >
          <FieldSet
            name="name"
            label={t('UsersSidePainelName')}
            rules={{
              required: t('UsersSidePainelErrorName')
            }}
          />
          <FieldSet
            name="email"
            type="email"
            label={t('UsersSidePainelEmail')}
            rules={{
              required: t('UsersSidePainelErrorEmail'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please input a valid email address.'
              }
            }}
          />
          <FieldSet
            name="mobile_phone"
            label={t('UsersSidePainelPhone')}
            rules={{
              pattern: {
                value: /^\(\d{2}\) \d{4,5}-\d{3,4}$/,
                message: t('Invalid phone number')
              }
            }}
            onInput={(e) => (e.target.value = formatPhoneNumberWithMask(e.target.value))}
          />
          {auth.user.role === 'admin' && (
            <FieldSet
              name="reset2fa"
              label={t('UsersSidePainelRemove2FA')}
              input={
                <Button type="button" variant="link" onClick={() => setIsModalOpen(true)}>
                  {t('UsersSidePainelRemove2FA')}
                </Button>
              }
            />
          )}
          <FieldSet
            name="role"
            label={t('UsersSidePainelRole')}
            rules={{ required: t('UsersSidePainelErrorRole') }}
            input={<SelectInput options={MERCHANT_ROLES} />}
          />
          <SwitchInput name="active" label={t('UsersSidePainelUser')} />
          {!!merchantId ? <HiddenInput name="merchant_id" value={merchantId} /> : <></>}
        </Form>
      </div>
      {isModalOpen && (
        <Dialog.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
          <Dialog.Content maxWidth="480px">
            <div className="flex justify-center mb-4">
              <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500" />
            </div>
            <Dialog.Title className="text-lg font-bold mb-6">{t('ConfirmRemove2FATitle')}</Dialog.Title>
            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('ConfirmRemove2FAMessage')}
            </Dialog.Description>
            <Flex justify="between" mt="6" gap="2">
              <Button variant="white" className="w-[48%]" onClick={() => setIsModalOpen(false)}>
                {t('Cancel')}
              </Button>
              <Button className="w-[48%]" variant="fail" onClick={handleRemove2fa}>
                {t('Confirm')}
              </Button>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
      )}
    </div>
  )
}
