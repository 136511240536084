import { WalletIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import PageHeader from '../../../../components/Pages/PageHeader'
import Table from '../../../../components/Table/Table'
import { useTranslation } from 'react-i18next'
import gatewayApi from '../../../../services/gatewayApi'
import { toast } from 'react-hot-toast'

export default function MerchantSettingsWallet() {
  const { t } = useTranslation()
  const { merchantId } = useParams()
  const { setActivePage } = useOutletContext()

  const [wallet_id, setWalletId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({ meta: {}, processors: [] })

  useEffect(() => {
    setActivePage('wallet')

    const fetchWallet = async () => {
      try {
        const response = await gatewayApi.get(`/app/admin/wallet/${merchantId}`)

        if (response.data.wallet_id) {
          setWalletId(response.data.wallet_id)
        }
      } catch (e) {
        console.error('Erro ao buscar a carteira:', e)
        toast.error('Erro ao buscar a carteira!')
      } finally {
        setIsLoading(false)
      }
    }

    fetchWallet()
  }, [merchantId, setActivePage])

  useEffect(() => {
    if (wallet_id) {
      setData({
        meta: {
          total: 1,
          page: 1,
          per_page: 1
        },
        processors: [
          {
            id: wallet_id,
            alias: 'Wallet Principal'
          }
        ]
      })
    }
  }, [wallet_id])

  const columns = [
    {
      title: t('MerchantSettingsWalletIdentification'),
      render: ({ id }) => <span className="mb-px block text-xs uppercase text-gray-600">{id}</span>
    },
    {
      title: t('MerchantSettingsWalletLabel'),
      render: ({ alias }) => <span>{alias}</span>
    }
  ]

  return (
    <div>
      <Outlet />

      <PageHeader
        title={t('MerchantSettingsWallet')}
        variant="simple"
        button={
          <Button as={<Link to={`/admin/merchant/${merchantId}/wallet/deposit/${wallet_id}`} />} size="sm">
            <WalletIcon className="mr-2 h-4" />
            {t('MerchantSettingsButtonNewDeposit')}
          </Button>
        }
      />

      <Table
        columns={columns}
        rows={data.processors}
        isLoading={isLoading}
        emptyState={<EmptyState icon={<WalletIcon />} title={t('MerchantSettingsWalletTitle')} />}
      />
    </div>
  )
}
