import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const SelectInput = ({ options, value, onChange, isLoading, placeholder }) => {
  const [inputValue, setInputValue] = useState(value || '')
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  useEffect(() => {
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())))
  }, [inputValue, options])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setInputValue('')
      onChange(undefined)
      return
    }

    const option = options.find(({ label }) => label.toLowerCase().includes(label.toLowerCase()))

    setInputValue(e.target.value)
    onChange(option.value)
    setIsOpen(true)
  }

  const handleSelectOption = (option) => {
    setInputValue(option.label)
    onChange(option.value)
    setIsOpen(false)
  }

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="w-full border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
        disabled={isLoading}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen && !isLoading && filteredOptions.length > 0 && (
        <ul className="absolute z-10 w-full mt-1 max-h-40 overflow-auto border border-gray-300 rounded-md bg-white shadow-lg">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className="px-3 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}

      {isLoading && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <svg
            className="animate-spin h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path d="M22 12c0 5.5-4.5 10-10 10S2 17.5 2 12" />
          </svg>
        </div>
      )}
    </div>
  )
}

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string
}

SelectInput.defaultProps = {
  value: '',
  isLoading: false,
  placeholder: 'Select...'
}

export default SelectInput
