import React, { useMemo, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { FormatDate, FormatTime } from '../../components/DateTime/FormatDate'
import PixIcon from '../../components/Icons/PixIcon'
import Main from '../../components/Main/Main'
import PageHeader from '../../components/Pages/PageHeader'
import Table from '../../components/Table/Table'
import { fetchTransactionsList } from '../../services/gatewayApi'
import { TransactionAmount, TransactionHash } from '../../components/TransactionHelpers'
import TransactionStatus from '../../components/Transaction/TransactionStatus'
import { transactionTypesMap } from '../../data/TransactionTypes'
import Filters from '../../components/Filters/Filters'
import TabButton from '../../components/TabButton/TabButton'
import { sourceTypesMap } from '../../data/sourceTypes'
import { useAuth } from '../../contexts/AuthContext'
import { discoverField } from '../../utils/fieldUtils'

const initialFilters = {
  type: ['charge'],
  status: [],
  source: []
}

export default function ListTransactions() {
  const location = useLocation()
  const { t } = useTranslation()
  const auth = useAuth()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const [currentPage, setCurrentPage] = useState(page)
  const [searchQueryValues, setSearchQueryValues] = useState({
    name: '',
    e2e: '',
    document: '',
    email: ''
  })
  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const queryClient = useQueryClient()
  const searchQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''
  const normalizedFilters = useMemo(
    () => ({
      ...searchQueryValues,
      ...selectedFilters
    }),
    [searchQueryValues, selectedFilters]
  )

  const filters = [
    {
      id: 'status',
      title: t('TransactionsSidePainelStatus'),
      options: [
        { id: 'success', title: t('TransactionsFiltersTypeSuccess') },
        { id: 'failed', title: t('TransactionsFiltersTypeFailed') },
        { id: 'pending', title: t('TransactionsFiltersTypePending') },
        ...(selectedFilters.type.includes('charge')
          ? [{ id: 'expired', title: t('TransactionsFiltersTypeExpired') }]
          : [])
      ]
    },
    selectedFilters.type.length === 1 &&
      auth.user.role !== 'merchant_employee' &&
      selectedFilters.type.includes('payment') && {
        id: 'source',
        title: t('TransactionsSidePainelPaymentType'),
        options: [
          { id: 'player', title: t('TransactionsFiltersPaymentTypePlayers') },
          { id: 'vendor', title: t('TransactionsFiltersPaymentTypeVendors') },
          { id: 'settlement', title: t('TransactionsFiltersPaymentTypeSettlement') }
        ]
      }
  ].filter(Boolean)

  const { isLoading, data, isRefetching } = useQuery({
    queryKey: ['listTransactions', { currentPage }],
    meta: { filters: normalizedFilters },
    queryFn: fetchTransactionsList
  })

  useEffect(() => {
    if (location.pathname === '/merchant/transactions' && !!page) {
      setCurrentPage(page)
    }
  }, [page])

  useEffect(() => {
    ;(async () => {
      setIsFilterLoading(true)
      await queryClient.cancelQueries()
      await queryClient.fetchQuery({
        queryKey: ['listTransactions', { currentPage }],
        meta: { filters: normalizedFilters },
        queryFn: fetchTransactionsList
      })
      setIsFilterLoading(false)
    })()
  }, [normalizedFilters])

  const handleFilterChange = async (filters) => {
    await queryClient.cancelQueries()
    setCurrentPage(1)
    setSelectedFilters(filters)
  }

  const handleSearchQueryChange = (event) => {
    let value = event.target.value
    const field = discoverField(value)
    if (field === 'e2e') {
      value = value.toLowerCase()
    }

    const textValues = {
      e2e: '',
      name: '',
      document: '',
      email: '',
      [field]: value
    }

    setSearchQueryValues({ ...textValues })
  }

  const columns = [
    {
      title: t('TransactionsTableStatus'),
      headerClassName: 'text-center',
      render: (transaction) => <TransactionStatus transaction={transaction} size="small" />
    },
    {
      title: t('TransactionsTableTransaction'),
      render: (transaction) => (
        <Link to={`/merchant/transactions/${transaction.id}`} className="text-gray-500">
          <div className="flex items-center sm:hidden">
            <div>
              <PixIcon className="h-5 fill-sky-600 stroke-2" />
            </div>
            <div className="ml-2">
              <span className="block font-medium text-gray-800">
                <TransactionHash transaction={transaction.id} />
              </span>
              <span className="text-xs">
                <FormatDate date={transaction.created_at} locale="en-US" />
                &nbsp;
                <FormatTime date={transaction.created_at} locale="pt-BR" />
              </span>
            </div>
          </div>
          <div className="hidden font-semibold hover:text-gray-800 sm:block">
            <TransactionHash transaction={transaction.id} />
          </div>
        </Link>
      )
    },
    {
      title: t('TransactionsTableOwer'),
      render: ({ first_name, last_name, email }) => (
        <>
          <span className="block font-semibold text-gray-700">{`${first_name || 'N/A'} ${last_name || ''}`}</span>
          <span className="text-xs">{email}</span>
        </>
      )
    },
    {
      title: t('TransactionsTableDate'),
      onlyDesktop: true,
      render: ({ created_at }) => (
        <>
          <div className="text-sm font-medium text-gray-900">
            <FormatDate date={created_at} locale="en-US" />
          </div>
          <div className="text-xs text-gray-500">
            <FormatTime date={created_at} locale="pt-BR" />
          </div>
        </>
      )
    },
    {
      title: t('TransactionsTableType'),
      render: ({ transaction_type }) => (
        <div className="flex gap-1">
          {React.createElement(transactionTypesMap[transaction_type].icon, { className: 'fill-gray-600', height: 20 })}
          <span className="font-medium">{t(transactionTypesMap[transaction_type].label)}</span>
        </div>
      )
    },
    auth.user.role !== 'merchant_employee' && {
      title: t('TransactionsTablePaymentSource'),
      render: ({ source }) => (
        <div className="flex gap-1">
          <span className="font-medium">{t(sourceTypesMap[source || 'player']?.label)}</span>
        </div>
      )
    },
    {
      title: t('TransactionsTableAmount'),
      headerClassName: 'text-right',
      rowClassName: 'text-right',
      render: (transaction) => <TransactionAmount transaction={transaction} />
    }
  ]

  const currentTab = useMemo(() => {
    if (selectedFilters.type.includes('charge') && selectedFilters.type.includes('payment')) {
      return 'all'
    }

    if (selectedFilters.type.includes('charge')) {
      return 'charge'
    }

    return 'payment'
  }, [selectedFilters])

  return (
    <div>
      <Outlet />

      <PageHeader title={t('TransactionsTitle')} subtitle={t('TransactionsSubtitle')} />

      <Main>
        <div className="grid gap-1 mt-7 mb-4">
          <div className="flex gap-1">
            <TabButton
              active={currentTab === 'all'}
              onClick={() => {
                setSelectedFilters({ ...selectedFilters, type: ['charge', `payment`] })
              }}
            >
              {t('ListTransactionTabHistoric')}
            </TabButton>
            <TabButton
              active={currentTab === 'charge'}
              onClick={() => {
                if (currentTab !== 'charge') {
                  setSelectedFilters({ ...selectedFilters, type: ['charge'] })
                }
              }}
            >
              {t('ListTransactionTabCashIn')}
            </TabButton>
            <TabButton
              active={currentTab === 'payment'}
              onClick={() => {
                if (currentTab !== 'payment') {
                  setSelectedFilters({ ...selectedFilters, type: ['payment'] })
                }
              }}
            >
              {t('ListTransactionTabCashOut')}
            </TabButton>
          </div>
        </div>
      </Main>

      <Filters
        filters={filters}
        selectedFilters={selectedFilters}
        onChangeFilters={handleFilterChange}
        searchQuery={searchQuery}
        onChangeSearchQuery={handleSearchQueryChange}
        searchQueryPlaceholder={t('TransactionsInputSearch')}
        onReset={() => setSelectedFilters(initialFilters)}
      />

      <Main>
        <Table
          isRefetching={isRefetching}
          columns={columns}
          isLoading={isLoading || isFilterLoading}
          rows={data?.transactions || []}
          pagination={data?.meta || []}
          paginationBasePath="/merchant/transactions"
          hasFilters
        />
      </Main>
    </div>
  )
}
