import React, { useEffect, useState } from 'react'
import { BuildingLibraryIcon, ChartBarSquareIcon, UsersIcon, WalletIcon } from '@heroicons/react/24/outline'
import { Link, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Main from '../../../components/Main/Main'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../contexts/AuthContext'
import { useFlags } from 'flagsmith/react'

export default function MerchantSettings() {
  const flags = useFlags(['financial_features'])
  const flagFinancialFeatures = JSON.parse(flags.financial_features.value) || []
  const [isEmailAllowed, setIsEmailAllowed] = useState(false)
  const auth = useAuth()

  const { merchant, isLoading } = useOutletContext()
  const [activePage, setActivePage] = useState()
  const { merchantId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.user.email) {
      const isActiveFlag = flagFinancialFeatures.includes(auth.user.email)
      setIsEmailAllowed(isActiveFlag)
    }
  }, [])

  const subNavigation = [
    {
      name: t('AdminMerchantDashboard'),
      href: '',
      icon: ChartBarSquareIcon,
      current: (activePage) => activePage === 'dashboard'
    },
    {
      name: t('AdminMerchantProcessors'),
      href: 'processors',
      icon: BuildingLibraryIcon,
      current: (activePage) => activePage === 'processors'
    },
    {
      name: t('AdminMerchantUsers'),
      href: 'users',
      icon: UsersIcon,
      current: (activePage) => activePage === 'users'
    },
    ...(isEmailAllowed
      ? [
          {
            name: 'Carteira',
            href: 'wallet',
            icon: WalletIcon,
            current: (activePage) => activePage === 'wallet'
          }
        ]
      : [])
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <Main>
        <section>
          {/* Tabs */}
          <div className="border-b pb-6 md:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Select a tab
            </label>
            <select
              onChange={(e) => {
                navigate(e.target.value)
              }}
              id="selected-tab"
              name="selected-tab"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-chill-500 focus:outline-none focus:ring-chill-500 sm:text-sm"
            >
              <option value={`/admin/merchant/${merchantId}`}>Select a tab</option>
              {subNavigation.map((tab) => (
                <option key={tab.name} value={`/admin/merchant/${merchantId}/${tab.href}`}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>

          <div className="hidden md:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">
                {subNavigation.map((tab) => (
                  <Link
                    key={tab.name}
                    to={`/admin/merchant/${merchantId}/${tab.href}`}
                    className={classNames(
                      tab.current(activePage)
                        ? 'rounded-t-lg border border-b-white bg-white text-chill-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'flex items-center whitespace-nowrap border-b-2 px-8 py-4 text-sm font-medium'
                    )}
                  >
                    <tab.icon className="h-6 w-6" />
                    <span className="ml-2">{tab.name}</span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </section>
        <div className="">
          <div className="border border-t-0 bg-white px-4 py-4 shadow-sm sm:px-6 lg:px-8 lg:py-8">
            <div className="pb-10">
              <Outlet context={{ setActivePage, merchant, isLoading }} />
            </div>
          </div>
        </div>
      </Main>
    </>
  )
}
