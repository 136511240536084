import React from 'react'
import { Route } from 'react-router-dom'
import SupportAdminLayout from '../layouts/SupportAdminLayout'
import Transaction from '../pages/Support/Transactions/Transaction'
import TransactionReceipt from '../pages/Support/Transactions/TransactionReceipt'
import ListTransactions from '../pages/Support/Transactions/ListTransactions'

export default function SupportRouter() {
  return (
    <Route path="support" element={<SupportAdminLayout />}>
      <Route path="transactions" element={<ListTransactions />}>
        <Route path=":transactionId" element={<Transaction />}>
          <Route path="receipt" element={<TransactionReceipt />} />
        </Route>
      </Route>
    </Route>
  )
}
