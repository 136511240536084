import React, { useEffect, useMemo, useState } from 'react'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import InvoiceCard from '../../../components/InvoiceCard/InvoiceCard'
import { useTranslation } from 'react-i18next'
import { Search } from 'react-bootstrap-icons'
import { fetchMerchants } from '../../../services/ledgerApi'

const fetchRegulatedMerchants = async () => {
  const response = await fetchMerchants()
  return response
}

const InvoiceDisplay = () => {
  const { t } = useTranslation()
  const [merchantsRegulated, setMerchantsRegulated] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [balances, setBalances] = useState({})

  useEffect(() => {
    if (merchantsRegulated.length === 0) {
      const fetchData = async () => {
        try {
          const response = await fetchRegulatedMerchants()
          setMerchantsRegulated(response)
        } catch (error) {
          console.error('Erro ao buscar dados dos estabelecimentos', error)
        }
      }
      fetchData()
    }
  }, [merchantsRegulated])

  const filteredMerchants = useMemo(() => {
    return merchantsRegulated.filter(
      (m) => m.name.toLowerCase().includes(searchTerm.toLowerCase()) || m.id.toString().includes(searchTerm)
    )
  }, [merchantsRegulated, searchTerm])

  const sortedMerchants = useMemo(() => {
    return [...filteredMerchants].sort((a, b) => {
      const balanceA = balances[a.id] || 0
      const balanceB = balances[b.id] || 0

      return balanceB - balanceA
    })
  }, [filteredMerchants, balances])

  return (
    <div>
      <PageHeader title={t('FinancialMerchant.title')} subtitle={t('FinancialMerchant.subtitle')} />

      <Main className="pt-8">
        <div className="flex flex-col gap-4 mb-8">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <Search className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-[500px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Pesquisar por nome ou ID do merchant"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            {sortedMerchants.length > 0 ? (
              sortedMerchants.map((merchant) => (
                <InvoiceCard
                  key={merchant.id}
                  merchant_id={merchant.id}
                  merchant_name={merchant.name}
                  setBalances={setBalances}
                />
              ))
            ) : (
              <p className="text-gray-500 col-span-full text-center">Nenhum resultado encontrado</p>
            )}
          </div>
        </div>
      </Main>
    </div>
  )
}

export default InvoiceDisplay
