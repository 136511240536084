import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'

export default function ProtectedLayout() {
  const auth = useContext(AuthContext)
  const currentPath = useLocation()

  if (!auth.user) {
    return <Navigate to="/auth/sign-in" />
  }

  if (currentPath.pathname === '/') {
    if (auth.user.role === 'admin') {
      return <Navigate to="/admin" />
    } else if (auth.user.role === 'support_admin') {
      return <Navigate to="/support/transactions" />
    } else {
      return <Navigate to="/merchant" />
    }
  }

  return <Outlet />
}
