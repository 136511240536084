import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Dialog, Flex } from '@radix-ui/themes'
import { useMutation, useQuery } from '@tanstack/react-query'

import { fetchFeeTransfer } from '../../../services/gatewayApi'
import Form from '../../../components/Form/Form'
import Button from '../../../components/Button/Button'
import FieldSet from '../../../components/Form/FieldSet'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import CurrencyInput from '../../../components/Form/Inputs/CurrencyInput'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SelectInput from '../../../components/SelectInput/SelectInput'
import ledgerApi from '../../../services/ledgerApi'
import gatewayApi from '../../../services/gatewayApi'
import { fetchBalance } from '../../../services/finance'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Skeleton from '../../../components/Skeleton/Skeleton'

const fetchBs2 = async (merchant_id) => {
  const response = await fetchBalance(merchant_id, 'bs2')
  return response.data
}

const fetchCelCoin = async (merchant_id) => {
  const response = await fetchBalance(merchant_id, 'celcoin')
  return response.data
}

const fetchMerchants = async () => {
  try {
    const response = await ledgerApi.get('/v1/admin/merchants', {
      params: { q: 'is_regulated' }
    })
    return response.data
  } catch (error) {
    console.error('Erro ao buscar merchants:', error)
    throw error
  }
}

const fetchMerchantProcessors = async ({ queryKey }) => {
  const [_key, { merchantId }] = queryKey
  try {
    const response = await gatewayApi.get('/app/admin/processors', {
      params: {
        merchant_id: merchantId
      }
    })
    return response.data.processors
  } catch (error) {
    console.error('Erro ao buscar processors:', error)
    throw error
  }
}

export default function FeeTransferMerchant() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const submitRef = useRef()
  const [selectedMerchant, setSelectedMerchant] = useState(null)
  const [selectedProcessor, setSelectedProcessor] = useState(null)
  const [selectedButton, setSelectedButton] = useState(null)
  const [openSidebar, setOpenSidebar] = useState(false)
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
  const [dataTransfer, setDataTransfer] = useState({})
  const [bs2Amount, setBs2Amount] = useState(null)
  const [celCoinAmount, setCelCoinAmount] = useState(null)
  const [isLoadingCelcoin, setIsLoadingCelcoin] = useState(false)
  const [isLoadingBs2, setIsLoadingBs2] = useState(false)

  const FEE_TRANSFER_DATA = {
    document_number: '41388405000126',
    pix_key_value: '1be23bbe-9fa1-4975-a8bc-3f3b2b7e4a43'
  }

  const { data: merchants, isLoading: isFetchingMerchants } = useQuery(['merchants'], fetchMerchants, {
    onError: (error) => {
      toast.error(t('FeeTransfer.alertErrorFetchingMerchants'))
    }
  })

  const {
    data: processors = [],
    isLoading: isFetchingProcessors,
    refetch: refetchProcessors
  } = useQuery(['processors', { merchantId: selectedMerchant }], fetchMerchantProcessors, {
    enabled: !!selectedMerchant,
    onError: (error) => {
      toast.error(t('FeeTransfer.alertErrorFetchingProcessors'))
    }
  })

  useEffect(() => {
    if (!selectedMerchant) {
      return
    }

    setIsLoadingCelcoin(true)
    fetchCelCoin(selectedMerchant)
      .then((data) => {
        setCelCoinAmount(data?.amount || null)
      })
      .catch(() => {
        setCelCoinAmount(null)
      })
      .finally(() => {
        setIsLoadingCelcoin(false)
      })

    setIsLoadingBs2(true)
    fetchBs2(selectedMerchant)
      .then((data) => {
        setBs2Amount(data?.amount || null)
      })
      .catch(() => {
        setBs2Amount(null)
      })
      .finally(() => {
        setIsLoadingBs2(false)
      })

    refetchProcessors()
  }, [selectedMerchant])

  const Processor_type = React.useMemo(() => {
    const hasCelcoin = processors?.some((processor) => processor.processor_type.toLowerCase() === 'celcoin')
    const hasBs2 = processors?.some((processor) => processor.processor_type.toLowerCase() === 'bs2')

    return [
      {
        value: 'celcoin',
        image: <img src="/celcoin.webp" alt="Celcoin" className="mx-auto" />,
        hint: t('PaymentsSidePanelButtonPayBrazil'),
        disabled: !hasCelcoin,
        label: `Celcoin`
      },
      {
        value: 'bs2',
        image: <img src="/bs2.webp" alt="BS2" className="mx-auto" />,
        hint: t('PaymentsSidePanelButtonSettlethePayment'),
        disabled: !hasBs2,
        label: `BS2`
      }
    ]
  }, [processors, t])

  const handleProcessorSelection = (value) => {
    const selected = processors?.find((processor) => processor.processor_type.toLowerCase() === value)
    if (selected) {
      setSelectedProcessor(selected)
      setSelectedButton(value)
    }
  }

  const handleMerchantChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedMerchant(selectedOption)
      setSelectedProcessor(null)
      setSelectedButton(null)
    } else {
      setSelectedMerchant(null)
    }
  }

  const { mutate: feeTransferMutate, isLoading: isTransferLoading } = useMutation({
    mutationFn: fetchFeeTransfer,
    onSuccess: (data) => {
      toast.success(t('FeeTransfer.alertSuccess'))
    },
    onError: (error) => {
      toast.error(t('FeeTransfer.alertError'))
    }
  })

  const handleDataTransfer = (data) => {
    if (!selectedMerchant || !selectedProcessor) {
      toast.error(t('FeeTransfer.alertErrorNoMerchantOrProcessorSelected'))
      return
    }

    const dataBody = {
      merchant_transaction_id: uuidv4(),
      merchant_id: selectedMerchant,
      processor_id: selectedProcessor.id,
      amount: data.amount,
      source: 'internal',
      first_name: 'Paag',
      email: 'exemplo@paag.io',
      pix_key_type: 'random',
      pix_message: 'Transferência Fee Paag',
      pix_key_value: FEE_TRANSFER_DATA.pix_key_value,
      document_number: FEE_TRANSFER_DATA.document_number,
      observation: dataTransfer.observation
    }

    if (
      dataBody.merchant_transaction_id &&
      dataBody.merchant_id &&
      dataBody.processor_id &&
      dataBody.amount &&
      dataBody.source &&
      dataBody.first_name &&
      dataBody.email &&
      dataBody.pix_key_type &&
      dataBody.pix_key_value &&
      dataBody.document_number
    ) {
      setDataTransfer(dataBody)
      setOpenModalConfirmation(true)
    } else {
      console.error('Todos os campos do dataBody devem ser preenchidos corretamente.')
    }
  }

  const handleSubmitTransfer = (data) => {
    feeTransferMutate({ data })
    setDataTransfer({})
    setOpenModalConfirmation(false)
    setOpenSidebar(false)
  }

  const onError = (error) => console.error('error', error)

  useEffect(() => {
    setOpenSidebar(true)
  }, [])

  return (
    <div>
      <SidePanel open={openSidebar} setOpen={setOpenSidebar} onClose={() => navigate(`/admin/fee`)}>
        <div>
          <SidePanelHeader
            title={t('FeeTransfer.sidebarTitle')}
            onClose={() => setOpenSidebar(false)}
            onError={onError}
          />

          <SidePanelBody>
            <Form
              onSubmit={handleDataTransfer}
              submitRef={submitRef}
              className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
              onError={onError}
            >
              <FieldSet
                name="merchant_id"
                label="Merchant"
                input={
                  <SelectInput
                    placeholder={t('placeholderSelectMerchant')}
                    options={
                      merchants && merchants.length > 0
                        ? merchants.map((merchant) => ({
                            value: merchant.id,
                            label: merchant.name
                          }))
                        : []
                    }
                    value={selectedMerchant}
                    isLoading={isFetchingMerchants}
                    onChange={handleMerchantChange}
                  />
                }
              />
              <FieldSet
                name="processor_id"
                label={t('FeeTransfer.labelProcessorId')}
                input={
                  <div className="flex gap-4">
                    {Processor_type.map((option) => {
                      const amount = option.value === 'celcoin' ? celCoinAmount : bs2Amount
                      const isLoading = option.value === 'celcoin' ? isLoadingCelcoin : isLoadingBs2

                      return (
                        <button
                          key={option.value}
                          type="button"
                          className={`relative flex flex-col items-center justify-center p-4 rounded-lg border-2 w-60 transition-colors duration-200
                            ${selectedButton === option.value ? 'border-blue-500 bg-gray-200' : 'border-gray-300 bg-white hover:bg-gray-50'}
                            ${option.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                          onClick={() => !option.disabled && handleProcessorSelection(option.value)}
                          disabled={option.disabled || !selectedMerchant}
                        >
                          <div className="mb-2">{option.image}</div>
                          <div className="flex flex-col items-center gap-1 mt-1">
                            <p className="text-sm text-gray-900">Saldo</p>
                            {isLoading ? (
                              <Skeleton height={20} width={60} />
                            ) : amount !== null ? (
                              <FormattedCurrency
                                currency="BRL"
                                amount={amount}
                                disabled
                                className="text-center text-gray-900 border-none bg-transparent pointer-events-none"
                              />
                            ) : (
                              <span className="text-gray-500">N/A</span>
                            )}
                          </div>
                          {selectedButton === option.value && (
                            <CheckCircleIcon className="w-7 h-7 text-green-500 absolute -bottom-2 -right-2 bg-white rounded-full border-2 border-blue-500 p-1" />
                          )}
                        </button>
                      )
                    })}
                  </div>
                }
              />

              <FieldSet name="merchant_id" label={`CNPJ Paag`} value={FEE_TRANSFER_DATA.document_number} disabled />

              <FieldSet name="merchant_id" label={`Chave PIX`} value={FEE_TRANSFER_DATA.pix_key_value} disabled />

              <FieldSet
                name="amount"
                label={t('PaymentsSidePanelButtonPayAmount')}
                rules={{ required: t('FeeTransfer.inputErrorEmpty') }}
                input={<CurrencyInput currency="BRL" />}
                disabled={isTransferLoading}
              />
              <FieldSet
                name="observation"
                label={t('FeeTransfer.labelObservation')}
                input={
                  <textarea
                    className="w-full p-2 border border-gray-300 rounded-md"
                    maxLength={255}
                    value={dataTransfer.observation}
                    onChange={(e) =>
                      setDataTransfer((prev) => ({
                        ...prev,
                        observation: e.target.value
                      }))
                    }
                    placeholder={t('FeeTransfer.placeholderObservation')}
                  />
                }
              />
            </Form>
          </SidePanelBody>

          <SidePanelFooter>
            <Button type="button" variant="white" onClick={() => setOpenSidebar(false)}>
              {t('NewMerchantButton')}
            </Button>

            <Button
              type="submit"
              onClick={() => {
                submitRef.current.click()
              }}
            >
              {t('FeeTransfer.buttonPrimary')}
            </Button>
          </SidePanelFooter>
        </div>
      </SidePanel>

      {openModalConfirmation && (
        <Dialog.Root
          className="z-[100]"
          open={openModalConfirmation}
          onOpenChange={() => setOpenModalConfirmation(!openModalConfirmation)}
        >
          <Dialog.Content maxWidth="480px">
            <div className="flex justify-center mb-4">
              <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500" />
            </div>
            <Dialog.Title className="text-lg font-bold mb-6">{t('FeeTransfer.modalConfirmationTitle')}</Dialog.Title>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('FeeTransfer.labelPaagCnpj')}

              <Dialog.Description size="2" mb="4" className="font-normal">
                {FEE_TRANSFER_DATA.document_number}
              </Dialog.Description>
            </Dialog.Description>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('FeeTransfer.labelPaagPix')}

              <Dialog.Description size="2" mb="4" className="font-normal">
                {FEE_TRANSFER_DATA.pix_key_value}
              </Dialog.Description>
            </Dialog.Description>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('FeeTransfer.labelMerchantId')}

              <Dialog.Description size="2" mb="4" className="font-normal">
                {selectedMerchant}
              </Dialog.Description>
            </Dialog.Description>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('FeeTransfer.labelProcessorId')}

              <Dialog.Description size="2" mb="4" className="font-normal">
                {selectedProcessor?.processor_type}
              </Dialog.Description>
            </Dialog.Description>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('PaymentsSidePanelButtonPayAmount')}:
              <Dialog.Description size="2" mb="4" className="font-normal">
                <FormattedCurrency amount={dataTransfer.amount} currency="BRL" />
              </Dialog.Description>
            </Dialog.Description>

            <Dialog.Description size="2" mb="4" className="font-semibold">
              {t('FeeTransfer.labelObservation')}:
              <Dialog.Description size="2" mb="4" className="font-normal">
                {dataTransfer.observation || 'N/A'}
              </Dialog.Description>
            </Dialog.Description>

            <Flex justify="between" mt="6" gap="2">
              <Button variant="white" className="w-[48%]" onClick={() => setOpenModalConfirmation(false)}>
                {t('CancelButton')}
              </Button>

              <Button
                className="w-[48%]"
                onClick={() => {
                  handleSubmitTransfer(dataTransfer)
                }}
              >
                {t('FeeTransfer.buttonPrimary')}
              </Button>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
      )}
    </div>
  )
}
