export const sourceTypesMap = {
  player: {
    label: 'sourceTypes.player'
  },
  vendor: {
    label: 'sourceTypes.vendor'
  },
  settlement: {
    label: 'sourceTypes.settlement'
  },
  funding: {
    label: 'sourceTypes.funding'
  },
  internal: {
    label: 'sourceTypes.internal'
  }
}
