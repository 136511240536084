import React, { useMemo, useEffect, useState, useTransition } from 'react'
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { FormatDate, FormatTime } from '../../../components/DateTime/FormatDate'
import PixIcon from '../../../components/Icons/PixIcon'
import PageHeader from '../../../components/Pages/PageHeader'
import Table from '../../../components/Table/Table'
import { FormattedCurrency, TransactionAmount, TransactionHash } from '../../../components/TransactionHelpers'
import TransactionStatus from '../../../components/Transaction/TransactionStatus'
import { transactionTypesMap } from '../../../data/TransactionTypes'
import { sourceTypesMap } from '../../../data/sourceTypes'
import { useAuth } from '../../../contexts/AuthContext'
import Main from '../../../components/Main/Main'
import SelectInput from '../../../components/SelectInput/SelectInput'
import ledgerApi from '../../../services/ledgerApi'
import gatewayApi, { getTransactionsByMerchantId } from '../../../services/gatewayApi'
import Datepicker from 'react-tailwindcss-datepicker'

const fetchMerchants = async () => {
  try {
    const response = await ledgerApi.get('/v1/admin/merchants', {
      params: { q: 'is_regulated' }
    })
    return response.data
  } catch (error) {
    console.error('Erro ao buscar merchants:', error)
    throw error
  }
}

const initialFilters = {
  type: [],
  status: [],
  source: ['internal']
}

export default function ListFees() {
  const location = useLocation()
  const auth = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedMerchant, setSelectedMerchant] = useState()
  const page = searchParams.get('page') || 1
  const { t, i18n } = useTranslation()
  const [currentPage, setCurrentPage] = useState(page)
  const [searchQueryValues, setSearchQueryValues] = useState({
    name: '',
    e2e: '',
    document: '',
    email: ''
  })
  const [period, setPeriod] = useState({
    startDate: null,
    endDate: null
  })
  const [consolidatedValue, setConsolidatedValue] = useState('')

  const fetchMerchantTransactions = async ({ queryKey }) => {
    const [, { merchant_id, currentPage, status, startDate, endDate }] = queryKey
    const response = await getTransactionsByMerchantId({
      queryKey: ['transactions', { merchant_id: merchant_id, currentPage }],
      signal: null,
      meta: {
        filters: {
          source: ['internal'],
          status: status || [],
          start_date: startDate || null,
          end_date: endDate || null
        },
        limit: 15
      }
    })
    return response
  }

  const fetchSumTransactions = async (merchant_id) => {
    try {
      const response = await gatewayApi.post(`app/admin/merchant/${merchant_id}/sum-transactions`, {
        filter: {
          source: ['internal'],
          status: selectedFilters.status,
          start_date: period.startDate,
          end_date: period.endDate
        }
      })
      return response.data.total_amount || 0
    } catch (error) {
      console.error('Erro ao buscar soma das transações:', error)
      throw error
    }
  }

  const [isFetchingMerchants, startFetchMerchantTransition] = useTransition()
  const [merchants, setMerchants] = useState([])
  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const queryClient = useQueryClient()
  const searchQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''
  const normalizedFilters = useMemo(
    () => ({
      ...searchQueryValues,
      ...selectedFilters,
      startDate: period.startDate,
      endDate: period.endDate
    }),
    [searchQueryValues, selectedFilters, period]
  )

  const { isLoading, data, isRefetching } = useQuery({
    queryKey: ['listTransactions', { merchant_id: selectedMerchant, currentPage, ...normalizedFilters }],
    queryFn: fetchMerchantTransactions,
    keepPreviousData: true
  })

  useEffect(() => {
    startFetchMerchantTransition(async () => {
      try {
        const data = await fetchMerchants()
        setMerchants(data)
      } catch (e) {
        toast.error(t('FinancialMerchant.alertFetchMerchantsError'))
      }
    })
  }, [])

  useEffect(() => {
    if (data) {
      setTransactions(data)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname === '/admin/fee-history' && !!page) {
      setCurrentPage(page)
    }
  }, [page, location.pathname])

  useEffect(() => {
    ;(async () => {
      setIsFilterLoading(true)
      await queryClient.cancelQueries()
      await queryClient.fetchQuery({
        queryKey: ['listTransactions', { merchant_id: selectedMerchant, currentPage, ...normalizedFilters }],
        queryFn: fetchMerchantTransactions
      })
      setIsFilterLoading(false)
    })()
  }, [normalizedFilters, currentPage, selectedMerchant])

  useEffect(() => {
    if (selectedMerchant) {
      ;(async () => {
        try {
          const totalAmount = await fetchSumTransactions(selectedMerchant)
          setConsolidatedValue(totalAmount)
        } catch (e) {
          toast.error(t('Erro ao buscar valor consolidado'))
          setConsolidatedValue(0)
        }
      })()
    }
  }, [selectedMerchant, selectedFilters, period])

  const handleFilterChange = async (filters) => {
    await queryClient.cancelQueries()
    setCurrentPage(1)
    setSelectedFilters({
      ...filters,
      source: ['internal']
    })
  }

  const handleMerchantChange = (value) => {
    setSelectedMerchant(value)
    setCurrentPage(1)
    setSearchParams({ page: 1 })
  }

  const handleStatusFilterChange = (event) => {
    const status = event.target.value
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      status: status ? [status] : []
    }))
  }

  const handleValueChange = (newValue) => {
    setPeriod({
      startDate: newValue.startDate,
      endDate: newValue.endDate
    })
  }

  const columns = [
    {
      title: t('TransactionsTableStatus'),
      headerClassName: 'text-center',
      render: (transaction) => <TransactionStatus transaction={transaction} size="small" />
    },
    {
      title: t('TransactionsTableTransaction'),
      headerClassName: 'text-left',
      render: (transaction) => (
        <div className="text-gray-500">
          <div className="flex items-center sm:hidden">
            <div>
              <PixIcon className="h-5 fill-sky-600 stroke-2" />
            </div>
            <div className="ml-2">
              <span className="block font-medium text-gray-800">{transaction.id}</span>
              <span className="text-xs">
                <FormatDate date={transaction.created_at} locale="en-US" />
                &nbsp;
                <FormatTime date={transaction.created_at} locale="pt-BR" />
              </span>
            </div>
          </div>
          <div className="hidden font-semibold sm:block">{transaction.id}</div>
        </div>
      )
    },
    {
      title: t('TransactionsTableOwer'),
      render: ({ first_name, last_name, email }) => (
        <>
          <span className="block font-semibold text-gray-700">{`${first_name || 'N/A'} ${last_name || ''}`}</span>
          <span className="text-xs">{email}</span>
        </>
      )
    },
    {
      title: t('TransactionsTableDate'),
      onlyDesktop: true,
      render: ({ created_at }) => (
        <>
          <div className="text-sm font-medium text-gray-900">
            <FormatDate date={created_at} locale="en-US" />
          </div>
          <div className="text-xs text-gray-500">
            <FormatTime date={created_at} locale="pt-BR" />
          </div>
        </>
      )
    },
    {
      title: t('TransactionsTableResponsavel'),
      render: ({ responsable }) => (
        <>
          <span className="block font-semibold text-gray-700">{responsable}</span>
        </>
      )
    },
    {
      title: t('TransactionsTableObs'),
      render: (transaction) => <div className="text-sm text-gray-700">{transaction.observation || 'N/A'}</div>
    },
    {
      title: t('TransactionsTableAmount'),
      headerClassName: 'text-right',
      rowClassName: 'text-right',
      render: (transaction) => <TransactionAmount transaction={transaction} />
    }
  ]

  return (
    <div>
      <Outlet />

      <PageHeader title={t('FeesTitle')} subtitle={t('FeesSubtitle')} />

      <Main>
        <div className="text-left mt-4 flex items-center gap-4">
          <div className="w-96">
            <label htmlFor="merchant" className="block text-sm font-medium text-gray-700">
              {t('feeInpunt')}
            </label>
            <SelectInput
              placeholder={t('placeholderSelectMerchant')}
              options={merchants.map((merchant) => ({
                value: merchant.id,
                label: merchant.name
              }))}
              value={selectedMerchant}
              isLoading={isFetchingMerchants}
              onChange={handleMerchantChange}
            />
          </div>

          <div className="w-32">
            <label htmlFor="status" className="block text-sm font-medium text-gray-700">
              {t('TransactionsSidePainelStatus')}
            </label>
            <select
              id="status"
              className="w-32 border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
              value={selectedFilters.status[0] || ''}
              onChange={handleStatusFilterChange}
              disabled={!selectedMerchant}
            >
              <option value="">{t('All')}</option>
              <option value="success">{t('TransactionsFiltersTypeSuccess')}</option>
              <option value="failed">{t('TransactionsFiltersTypeFailed')}</option>
              <option value="pending">{t('TransactionsFiltersTypePending')}</option>
            </select>
          </div>

          <div className="w-80">
            <label htmlFor="datepicker" className="block text-sm font-medium text-gray-700">
              {t('feedata')}
            </label>
            <Datepicker
              i18n={i18n.language}
              value={period}
              onChange={handleValueChange}
              displayFormat={t('DatepickerDisplayFormat')}
              separator={t('DatepickerSeparator')}
              showShortcuts={true}
              maxDate={new Date()}
              inputClassName="w-full border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
              placeholder={!period.startDate && !period.endDate ? t('Selecionar datas') : undefined}
              configs={{
                shortcuts: {
                  yesterday: t('DatepickerShortcutYesterday'),
                  currentMonth: t('DatepickerShortcutCurrentMonth'),
                  pastMonth: t('DatepickerShortcutPastMonth')
                }
              }}
              disabled={!selectedMerchant}
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md border border-gray-300 ml-48 w-80">
            <h3 className="text-sm font-medium text-gray-700">{t('consolidatedValue')}</h3>
            <p className="text-2xl mt-1 font-semibold text-gray-900">
              <span className="text-2xl text-gray-900">Total: </span>
              <FormattedCurrency amount={consolidatedValue} />
            </p>
          </div>
        </div>
      </Main>

      <Main className="mt-4">
        <Table
          isRefetching={isRefetching}
          columns={columns}
          isLoading={isLoading || isFilterLoading}
          rows={transactions?.transactions || []}
          pagination={transactions?.meta || []}
          paginationBasePath="/admin/fee-history"
          hasFilters
        />
      </Main>
    </div>
  )
}
