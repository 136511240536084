export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function filterLinksWithPermissions(navigation, auth) {
  if (!auth.user.permissions) {
    auth.Logout()
  }

  return navigation.filter((n) => {
    return !!auth.user.permissions[n.permission] && auth.user.permissions[n.permission][0] == '1'
  })
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\\/]/g, '\\$&') // $& means the whole matched string
}

export function stripNonNumbers(str = '') {
  return str.replace(/\D/g, '')
}

export function formatCNPJ(cnpj) {
  cnpj = cnpj.replace(/\D/g, '')
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
}
