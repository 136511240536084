import React, { useMemo, useEffect, useState, useTransition } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import PageHeader from '../../../components/Pages/PageHeader'
import Button from '../../../components/Button/Button'
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { FormatDate, FormatTime } from '../../../components/DateTime/FormatDate'
import { TransactionAmount, TransactionHash } from '../../../components/TransactionHelpers'
import { useAuth } from '../../../contexts/AuthContext'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import SelectInput from '../../../components/SelectInput/SelectInput'
import ledgerApi from '../../../services/ledgerApi'
import { fetchTaxTransaction } from '../../../services/gatewayApi'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import TabButton from '../../../components/TabButton/TabButton'
import Datepicker from 'react-tailwindcss-datepicker'
import dayjs from 'dayjs'
import Filters from '../../../components/Filters/Filters'
import StatsCard from '../../../components/StatsCard/StatsCard'

export default function FeeWithdrawal() {
  const { t, i18n } = useTranslation()

  const auth = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedMerchant, setSelectedMerchant] = useState()
  const page = searchParams.get('page') || 1
  const [currentPage, setCurrentPage] = useState(page)

  const getToday = () => {
    const date = new Date(new Date().getTime() - 60 * 60 * 1000 * 3)
    return date.toISOString().slice(0, 10)
  }
  const todayDate = getToday()
  const [fee_sum, setfee_sum] = useState('000.000.000')
  const [period, setPeriod] = useState({
    startDate: null,
    endDate: null
  })
  const handleValueChange = (newValue) => {
    setPeriod(newValue)
    setSelectedMerchant(selectedMerchant)
    setCurrentPage(page)
    setSearchParams({ page: 1 })
    setfee_sum(fee_sum)
  }

  const initialFilters = {
    type: ['charge'],
    status: [],
    source: []
  }
  const [searchQueryValues, setSearchQueryValues] = useState({
    name: '',
    e2e: '',
    document: '',
    email: ''
  })

  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const queryClient = useQueryClient()
  const searchQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''
  const normalizedFilters = useMemo(
    () => ({
      ...searchQueryValues,
      ...selectedFilters
    }),
    [searchQueryValues, selectedFilters]
  )

  const filters = [
    {
      id: 'status',
      title: t('TransactionsSidePainelStatus'),
      options: [
        { id: 'success', title: t('TransactionsFiltersTypeSuccess') },
        { id: 'failed', title: t('TransactionsFiltersTypeFailed') },
        { id: 'pending', title: t('TransactionsFiltersTypePending') }
      ]
    },
    {
      id: 'source',
      title: t('TransactionsSidePainelPaymentType'),
      options: [
        { id: 'player', title: t('TransactionsFiltersPaymentTypePlayers') },
        { id: 'vendor', title: t('TransactionsFiltersPaymentTypeVendors') },
        { id: 'settlement', title: t('TransactionsFiltersPaymentTypeSettlement') }
      ]
    }
  ]

  const handleFilterChange = async (filters) => {
    await queryClient.cancelQueries()
    setCurrentPage(page)
    setSelectedFilters(filters)
  }

  const handleSearchQueryChange = (event) => {
    let value = event.target.value
    const field = discoverField(value)
    if (field === 'e2e') {
      value = value.toLowerCase()
    }

    const textValues = {
      e2e: '',
      name: '',
      document: '',
      email: '',
      [field]: value
    }

    setSearchQueryValues({ ...textValues })
  }

  const fetchMerchantTransactions = async ({ queryKey }) => {
    const [, { merchant_id, currentPage }] = queryKey
    const response = await fetchTaxTransaction({
      queryKey: ['transactions', { merchant_id: merchant_id, currentPage }],
      signal: null,
      meta: { filters: normalizedFilters },
      period: { period }
    })
    setfee_sum(response?.fee_sum ?? '000.000.000')
    return response
  }

  const fetchMerchants = async () => {
    try {
      const response = await ledgerApi.get('/v1/admin/merchants', {
        params: { q: 'is_regulated' }
      })
      return response.data
    } catch (error) {
      console.error('Erro ao buscar merchants:', error)
      throw error
    }
  }

  const [isFetchingMerchants, startFetchMerchantTransition] = useTransition()
  const [merchants, setMerchants] = useState([])

  // const [transactions, setTransactions] = useState([])

  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: ['FeeWithdrawal', { merchant_id: selectedMerchant, currentPage, period }],
    queryFn: fetchMerchantTransactions,
    // enabled: !!selectedMerchant,
    keepPreviousData: false
  })

  const handleMerchantChange = (value) => {
    setSelectedMerchant(value)
    setCurrentPage(page)
    setSearchParams({ page: 1 })
  }

  useEffect(() => {
    startFetchMerchantTransition(async () => {
      try {
        const data = await fetchMerchants()
        setMerchants(data)
      } catch (e) {
        toast.error(t('FinancialMerchant.alertFetchMerchantsError'))
      }
    })
  }, [])

  useEffect(() => {
    if (location.pathname === '/admin/fee' && !!page) {
      setCurrentPage(page)
    }
  }, [page, location.pathname])

  useEffect(() => {
    if (selectedMerchant) {
      refetch()
    }
  }, [period, selectedMerchant, currentPage])

  useEffect(() => {
    if (data) {
      setTransactions(data)
    }
  }, [data])

  useEffect(() => {
    ;(async () => {
      setIsFilterLoading(true)
      await queryClient.cancelQueries()
      await queryClient.fetchQuery({
        queryKey: ['FeeWithdrawal', { merchant_id: selectedMerchant, currentPage, period }],
        queryFn: fetchMerchantTransactions
      })
    })()
    setIsFilterLoading(false)
  }, [normalizedFilters, currentPage, period, selectedMerchant]) //, fee_sum

  const columns = [
    {
      title: t('TransactionsTablePaymentSource'),
      render: ({ fee }) => (
        <div className="flex gap-1">
          <span className="font-medium">{fee.merchant.name}</span>
        </div>
      )
    },
    {
      title: t('TransactionsTableDate'),
      onlyDesktop: true,
      render: ({ fee }) => (
        <>
          <div className="text-xs text-gray-500">
            <FormatDate
              date={fee.date_window.start_date}
              options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }}
              locale="pt-BR"
            />
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <FormatDate
              date={fee.date_window.end_date}
              options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }}
              locale="pt-BR"
            />
          </div>
        </>
      )
    },
    {
      title: t('TransactionsTableAmount'),
      headerClassName: 'text-right',
      rowClassName: 'text-right',
      render: ({ fee }) => <FormattedCurrency amount={fee.amount} currency="BRL" />
    }
  ]

  return (
    <div>
      <PageHeader
        title={t('FeeWithdrawal.title')}
        subtitle={t('FeeWithdrawal.subtitle')}
        button={
          <Button as={<Link />} to="/admin/fee/new-transfer">
            {t('FeeWithdrawal.button')}
          </Button>
        }
      />

      <Main className="mt-4">
        <div
          className="-mb-[10px] flex flex-col space-y-2 rounded-t-md border border-b-0 bg-white p-3 px-4 
        text-sm font-semibold text-gray-700 
        md:flex-row md:items-center md:justify-end md:space-x-2 md:space-y-0"
        >
          <div className="w-90 items-right justify-end font-semibold text-gray-700">
            <span className="font-semibold h-6 text-gray-700">Valor consolidado para os filtros</span>
            <br></br>
            Taxas geradas: <span className="font-normal text-gray-900">{fee_sum}</span>
          </div>
        </div>
        <div className="-mb-[10px] flex flex-col space-y-2 rounded-t-md border border-b-0 bg-white p-3 px-4 text-sm font-semibold text-gray-700 md:flex-row md:items-center md:justify-end md:space-x-2 md:space-y-0">
          <div className="text-left w-90">
            <SelectInput
              placeholder={t('placeholderSelectMerchant')}
              options={merchants.map((merchant) => ({
                value: merchant.id,
                label: merchant.name
              }))}
              value={selectedMerchant}
              isLoading={isFetchingMerchants}
              onChange={handleMerchantChange}
            />
          </div>
          <div className="mr-2 mt-3">
            <Datepicker
              i18n={i18n.language}
              value={period}
              onChange={handleValueChange}
              displayFormat={t('DatepickerDisplayFormat')}
              separator={t('DatepickerSeparator')}
              showShortcuts={true}
              maxDate={new Date()}
              inputClassName="bg-white border border-gray-300 text-black rounded-md"
              placeholder={!period.startDate && !period.endDate ? t('Selecionar datas') : undefined}
              configs={{
                shortcuts: {
                  yesterday: t('DatepickerShortcutYesterday'),
                  currentMonth: t('DatepickerShortcutCurrentMonth'),
                  pastMonth: t('DatepickerShortcutPastMonth')
                }
              }}
            />
          </div>
        </div>
      </Main>

      <Main>
        <Table
          isRefetching={isRefetching}
          columns={columns}
          isLoading={isLoading || isFilterLoading}
          rows={transactions?.consolidated_list_fee || []}
          pagination={transactions?.pagination || []}
          paginationBasePath="/admin/fee"
          hasFilters
        />
      </Main>

      <Outlet />
    </div>
  )
}
