import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedCurrency } from '../TransactionHelpers'
import Skeleton from '../Skeleton/Skeleton'
import { fetchPriorityProcessor } from '../../services/gatewayApi'
import { getDateBr } from '../../utils/formatDateBr'
import { fetchBalance } from '../../services/finance'
import { fetchWalletByMerchantId } from '../../services/gatewayApi'

const fetchBs2 = async (merchant_id) => {
  const response = await fetchBalance(merchant_id, 'bs2')
  return response.data
}

const fetchCelCoin = async (merchant_id) => {
  const response = await fetchBalance(merchant_id, 'celcoin')
  return response.data
}

const fetchPriority = async (merchant_id) => {
  const response = await fetchPriorityProcessor(merchant_id)
  return response.data
}

const fetchWallet = async (merchantId) => {
  const response = await fetchWalletByMerchantId(merchantId)
  return response
}

export default function InvoiceCard({ merchant_id, merchant_name, setBalances }) {
  const [celCoinAmount, setCelCoinAmount] = useState('')
  const [celCoinDate, setCelCoinDate] = useState('')
  const [walletData, setWalletData] = useState('')
  const [walletDate, setWalletDate] = useState('')
  const [processor, setProcessor] = useState('')
  const [bs2Amount, setBs2Amount] = useState('')
  const [bs2Date, setBs2Date] = useState('')

  const { isLoading: isLoadingCelCoin } = useQuery({
    queryKey: ['celcoinAmount', merchant_id],
    queryFn: () => fetchCelCoin(merchant_id),
    retry: false,
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      const dateNow = getDateBr(data?.date)
      setCelCoinAmount(data?.amount)
      setCelCoinDate(dateNow)

      setBalances((prevBalances) => {
        const previousBalance = prevBalances[merchant_id]?.value || 0
        return {
          ...prevBalances,
          [merchant_id]: data.amount > 0 ? previousBalance + 1 : previousBalance
        }
      })
    },
    onError: () => {
      setCelCoinAmount(null)
    }
  })

  const { isLoading: isWalletLoading } = useQuery({
    queryKey: ['walletAmount', merchant_id],
    queryFn: () => fetchWallet(merchant_id),
    retry: false,
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      const dateNowFormatted = getDateBr(data.created_at)
      setWalletDate(dateNowFormatted)
      setWalletData(data?.amount)

      setBalances((prevBalances) => {
        const previousBalance = prevBalances[merchant_id] || 0
        return {
          ...prevBalances,
          [merchant_id]: data.amount > 0 ? previousBalance + 1 : previousBalance
        }
      })
    },
    onError: () => {
      setWalletData(null)
    }
  })

  const { isLoading: isLoadingProcessor } = useQuery({
    queryKey: ['processorPriority', merchant_id],
    queryFn: () => fetchPriority(merchant_id),
    retry: false,
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      setProcessor(data?.processors?.processor_type.toUpperCase())
    },
    onError: () => {
      setProcessor(null)
    }
  })

  const { isLoading: isLoadingBs2 } = useQuery({
    queryKey: ['bs2Amount', merchant_id],
    queryFn: () => fetchBs2(merchant_id),
    retry: false,
    refetchOnMount: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      const dateNowFormatted = getDateBr()
      setBs2Amount(data?.amount)
      setBs2Date(dateNowFormatted)

      setBalances((prevBalances) => {
        const previousBalance = prevBalances[merchant_id] || 0
        return {
          ...prevBalances,
          [merchant_id]: data.amount > 0 ? previousBalance + 1 : previousBalance
        }
      })
    },
    onError: () => {
      setBs2Amount(null)
    }
  })

  return (
    <div className="bg-white shadow-md rounded-md w-[350px]">
      <div className="bg-gray-100 px-4 py-3 rounded-t-md">
        <h3 className="text-lg font-medium whitespace-nowrap">{merchant_name}</h3>
        <h4 className="text-sm text-gray-700 mt-1 whitespace-nowrap"> {merchant_id}</h4>
      </div>
      <div className="p-4 text-md font-medium">
        <div className="flex justify-between pt-4 group">
          <div className="relative">
            <span className="peer cursor-pointer">Wallet:</span>
            <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden peer-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 whitespace-nowrap">
              Última Atualização: {walletDate}
            </div>
          </div>
          {isWalletLoading ? (
            <span className="min-w-[200px] text-gray-90">
              <Skeleton />
            </span>
          ) : walletData != null ? (
            <FormattedCurrency amount={walletData} currency="BRL" />
          ) : (
            <span>-</span>
          )}
        </div>

        <div className="flex justify-between pt-3">
          <div className="relative">
            <span className="peer cursor-pointer">BS2:</span>
            <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden peer-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 whitespace-nowrap">
              Última Atualização: {bs2Date}
            </div>
          </div>
          {isLoadingBs2 ? (
            <span className="min-w-[200px] text-gray-90">
              <Skeleton />
            </span>
          ) : bs2Amount != null ? (
            <FormattedCurrency amount={bs2Amount} currency="BRL" />
          ) : (
            <span>-</span>
          )}
        </div>

        <div className="flex justify-between pt-4">
          <div className="relative">
            <span className="peer cursor-pointer">CelCoin:</span>
            <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 hidden peer-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 whitespace-nowrap">
              Última Atualização: {celCoinDate}
            </div>
          </div>
          {isLoadingCelCoin ? (
            <span className="min-w-[200px] text-gray-90">
              <Skeleton />
            </span>
          ) : celCoinAmount != null ? (
            <FormattedCurrency amount={celCoinAmount} currency="BRL" />
          ) : (
            <span>-</span>
          )}
        </div>

        <div className="flex justify-between font-medium pt-4">
          <span>Processor:</span>
          {isLoadingProcessor ? (
            <span className="min-w-[200px] text-gray-90">
              <Skeleton />
            </span>
          ) : processor != null ? (
            <span className="text-red-500">{processor}</span>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>
    </div>
  )
}
