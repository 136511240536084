import React, { useState, useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { FunnelFill } from 'react-bootstrap-icons'
import { classNames } from '../../helpers/dom'
import Main from '../Main/Main'
import Datepicker from 'react-tailwindcss-datepicker'

export default function Filters({
  filters,
  onReset,
  selectedFilters,
  onChangeFilters,
  searchQuery,
  onChangeSearchQuery,
  searchQueryPlaceholder
}) {
  const { t, i18n } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const dropdownRef = useRef(null)

  const [period, setPeriod] = useState({
    startDate: null,
    endDate: null
  })

  const handleValueChange = (newValue) => {
    setPeriod(newValue)
    if (onChangeFilters) {
      onChangeFilters({
        ...selectedFilters,
        start_date: newValue.startDate ? new Date(newValue.startDate).toISOString().split('T')[0] : null,
        end_date: newValue.endDate ? new Date(newValue.endDate).toISOString().split('T')[0] : null
      })
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilters(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const handleChange = (filterId, optionId, target) => {
    const getNewSelectedFilters = () => {
      const newSelectedFilters = JSON.parse(JSON.stringify(selectedFilters || {}))

      if (newSelectedFilters[filterId]) {
        if (target.checked) {
          newSelectedFilters[filterId].push(optionId)
        } else {
          let index = newSelectedFilters[filterId].indexOf(optionId)
          newSelectedFilters[filterId].splice(index, 1)
        }
      } else {
        return { ...newSelectedFilters, [filterId]: [optionId] }
      }

      return newSelectedFilters
    }

    onChangeFilters(getNewSelectedFilters())
  }

  const totalCount = Object.keys(selectedFilters || {}).reduce((acc, filterKey) => {
    const value = selectedFilters[filterKey]

    if (!filters.some((item) => item.id === filterKey)) {
      return acc
    }

    return value.length + acc
  }, 0)

  return (
    <div>
      <Main>
        <div className="-mb-[10px] flex flex-col space-y-2 rounded-t-md border border-b-0 bg-white p-3 px-4 text-sm font-semibold text-gray-700 md:flex-row md:items-center md:justify-end md:space-x-2 md:space-y-0">
          {typeof searchQuery === 'string' && (
            <div className="grow">
              <input
                onChange={onChangeSearchQuery}
                value={searchQuery}
                className="box-border w-full rounded-md border p-2.5"
                placeholder={searchQueryPlaceholder}
              />
            </div>
          )}

          <div className="mr-2">
            <Datepicker
              i18n={i18n.language}
              value={period}
              onChange={handleValueChange}
              displayFormat={t('DatepickerDisplayFormat')}
              separator={t('DatepickerSeparator')}
              showShortcuts={true}
              maxDate={new Date()}
              inputClassName="bg-white border border-gray-300 text-black rounded-md"
              placeholder={!period.startDate && !period.endDate ? t('Selecionar datas') : undefined}
              configs={{
                shortcuts: {
                  yesterday: t('DatepickerShortcutYesterday'),
                  currentMonth: t('DatepickerShortcutCurrentMonth'),
                  pastMonth: t('DatepickerShortcutPastMonth')
                }
              }}
            />
          </div>

          <div
            className="hover-bg-gray-100 relative z-30 flex cursor-pointer items-center rounded-md border p-2.5"
            onClick={() => setShowFilters(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowFilters(!showFilters)
              }
            }}
            tabIndex={0}
          >
            <FunnelFill className="text-base text-gray-400" />
            <div className="ml-2">
              <span className="font-bold">{totalCount}</span> {t('TransactionsButtonFilters')}
            </div>
            <div
              ref={dropdownRef}
              className={classNames(
                showFilters ? 'block' : 'hidden',
                'absolute right-0 top-full mt-2 flex h-fit min-w-[200px] flex-col overflow-hidden rounded-md border bg-white shadow-lg'
              )}
            >
              <h4 className="border-b bg-secondary-100 p-4 text-base text-chill-700">
                {t('TransactionsButtonFilters')}
              </h4>
              <div className="p-4">
                {filters.map((filter) => (
                  <div className="mb-6" key={filter.id}>
                    <h5 className="mb-2 text-xs font-bold">{filter.title}</h5>
                    <ul>
                      {filter.options.map((option) => (
                        <li className="" key={option.id}>
                          <label className="hover-bg-gray-100 block rounded-md p-2">
                            <input
                              id={option.id}
                              type="checkbox"
                              readOnly
                              checked={Boolean(selectedFilters?.[filter.id]?.includes(option.id))}
                              onClick={(e) => handleChange(filter.id, option.id, e.target)}
                              className="focus-ring-chill-500 focus-ring-2 checked-bg-chill-600 h-4 w-4 rounded border-gray-300 bg-gray-100 text-secondary-600"
                            />
                            <span className="ml-2 font-normal">{option.title}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {onReset && (
            <button
              className="hover-border-gray-200 cursor-pointer rounded-md border border-white p-2.5"
              onClick={() => {
                setPeriod({ startDate: null, endDate: null })
                onReset()
              }}
            >
              {t('TransactionsButtonClearAll')}
            </button>
          )}
        </div>
      </Main>
    </div>
  )
}
