import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import Badge from '../../../../components/Badge/Badge'
import Button from '../../../../components/Button/Button'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import PageHeader from '../../../../components/Pages/PageHeader'
import Table from '../../../../components/Table/Table'
import YesNo from '../../../../components/YesNo/YesNo'
import { processorByCode } from '../../../../data/ProcessorCredentials'
import gatewayApi from '../../../../services/gatewayApi'
import { useTranslation } from 'react-i18next'
import ProcessorPrecedence from '../../../../components/Processors/Precedence'
import { useAuth } from '../../../../contexts/AuthContext'
import { useFlags } from 'flagsmith/react'
import { useMemo } from 'react'

const ProcessorType = ({ type }) => {
  const processor = processorByCode(type)
  return (
    <div>
      <Badge variant={processor?.color}>{processor?.name}</Badge>
    </div>
  )
}

export default function MerchantSettingsProcessor() {
  const flags = useFlags(['financial_features'])
  const flagFinancialFeatures = JSON.parse(flags.financial_features.value) || []
  const [isChangeFeeTransfer, setIsChangeFeeTransfer] = useState(false)

  const auth = useAuth()
  const { t } = useTranslation()
  const { merchantId } = useParams()
  const { setActivePage } = useOutletContext()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    meta: {},
    processors: []
  })

  const processorsBuckets = {}
  const getBucketSize = (processorId) => {
    gatewayApi
      .get(`/app/admin/processors/${processorId}/fichas-bucket`)
      .then((response) => {
        console.log(response)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (auth.user?.email) {
      const isActiveFlag = flagFinancialFeatures.includes(auth.user.email)
      setIsChangeFeeTransfer(isActiveFlag)
    }

    setActivePage('processors')

    gatewayApi
      .get('/app/admin/processors/dictkey', {
        params: {
          merchant_id: merchantId
        }
      })
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log({ error })
      })
  }, [merchantId, setActivePage])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: t('MerchantSettingsProcessorType'),
        render: ({ processor_type }) => <ProcessorType type={processor_type} />
      },
      {
        title: t('MerchantSettingsProcessorPrecedence'),
        render: (processor) => <ProcessorPrecedence processor={processor} />
      },
      {
        title: t('MerchantSettingsProcessor'),
        render: ({ id, alias }) => (
          <>
            <span onClick={() => getBucketSize(id)} className="mb-px block text-xs uppercase text-gray-600">
              {id}
            </span>
            <span>{alias}</span>
          </>
        )
      },
      {
        title: t('MerchantSettingsProcessorTerceiro'),
        render: ({ third_party_deposit_block }) => <YesNo test={third_party_deposit_block} />
      },
      {
        title: t('MerchantSettingsProcessorBureau'),
        render: ({ bureau_validation }) => <YesNo test={bureau_validation} />
      },
      {
        title: t('MerchantSettingsProcessorMode'),
        node: 'mode',
        render: ({ mode }) => (
          <Badge variant={mode == 'live' ? 'green' : 'pink'}>
            {mode == 'live' ? t('AdminMerchantModeLive') : t('AdminMerchantMode')}
          </Badge>
        )
      },
      {
        title: t('MerchantSettingsProcessorBucketStatus'),
        node: 'buckets',
        render: ({ id }) => <Badge>{processorsBuckets[id]}</Badge>
      },
      {
        title: t('MerchantSettingsProcessorCurrency'),
        node: 'currency',
        render: ({ currency }) => <Badge>{currency}</Badge>
      },
      {
        title: t('MerchantSettingsProcessorEnabled'),
        render: ({ enabled }) => <YesNo test={enabled} />
      }
    ]

    if (isChangeFeeTransfer) {
      baseColumns.splice(3, 0, {
        title: t('MerchantSettingsProcessorDictKey'),
        render: ({ credentials_3 }) => (
          <span className="mb-px block text-xs uppercase text-gray-600">{credentials_3 ?? 'Não cadastrado'}</span>
        )
      })

      baseColumns.splice(5, 0, {
        title: t('MerchantSettingsProcessorAccountNumber'),
        render: ({ credentials_4 }) => (
          <span className="mb-px block text-xs uppercase text-gray-600">{credentials_4 ?? 'Não cadastrado'}</span>
        )
      })
    }

    return baseColumns
  }, [isChangeFeeTransfer, t])

  return (
    <div>
      <Outlet />

      <PageHeader
        title={t('MerchantSettingsProcessors')}
        variant="simple"
        button={
          <Button as={<Link to={`/admin/merchant/${merchantId}/processors/new`} />} size="sm">
            <BuildingLibraryIcon className="mr-2 h-4" />
            {t('MerchantSettingsButtonNew')}
          </Button>
        }
      />

      <Table
        columns={columns}
        rows={data.processors}
        isLoading={isLoading}
        pagination={data.meta}
        emptyState={
          <EmptyState
            icon={<BuildingLibraryIcon />}
            title={t('MerchantSettingsProcessorTitle')}
            description={t('MerchantSettingsProcessorDescription')}
            action={<Button>{t('MerchantSettingsButtonNew')}</Button>}
          />
        }
      />
    </div>
  )
}
