export function formatUSDWithMask(value) {
  const cleaned = value.replace(/\D/g, '')

  if (cleaned.length === 0) {
    return ''
  }

  const floatValue = parseFloat(cleaned) / 100

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(floatValue)
}

export function formatCurrency(value) {
  const cleaned = value.replace(/\D/g, '')

  if (cleaned.length === 0) {
    return ''
  }

  const floatValue = parseFloat(cleaned) / 100

  return floatValue
}
