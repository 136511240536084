import React, { useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import {
  ChartPieIcon,
  UsersIcon,
  QueueListIcon,
  ArrowLeftOnRectangleIcon,
  BuildingOfficeIcon,
  WalletIcon
} from '@heroicons/react/24/outline'
import CustomToaster from '../components/CustomToaster/CustomToaster'
import Sidebar from '../components/Sidebar/Sidebar'
import Topbar from '../components/Topbar/Topbar'
import { filterLinksWithPermissions } from '../helpers/utils'
import ZendeskSnippet from '../components/Snippets/ZendeskSnippet'
import { useTranslation } from 'react-i18next'

export default function SupportAdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const navigation = [
    {
      name: t('NavigationButtonTransactions'),
      href: '/support/transactions',
      icon: QueueListIcon,
      current: false
    }
  ]

  const secondaryNavigation = [
    {
      name: t('NavigationButtonLogout'),
      href: '/auth/sign-out',
      icon: ArrowLeftOnRectangleIcon
    }
  ]

  const availableLinks = navigation

  if (auth.user.role !== 'support_admin') {
    return <Navigate to="/" />
  }

  if (location.pathname === '/support' || location.pathname === '/support/') {
    return <Navigate to={availableLinks[0].href} />
  }

  return (
    <>
      <div className="min-h-full">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={availableLinks}
          secondaryNavigation={secondaryNavigation}
        />

        <div className="flex flex-1 flex-col lg:pl-64">
          <Topbar userNavigation={[]} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1">
            <CustomToaster />
            <Outlet />
          </main>
        </div>

        <ZendeskSnippet />
      </div>
    </>
  )
}
