import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FieldSet from '../../../components/Form/FieldSet'
import Form from '../../../components/Form/Form'
import SelectInput from '../../../components/Form/Inputs/SelectInput'
import TextInput from '../../../components/Form/Inputs/TextInput'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import { TRANSACTION_TYPES } from '../../../data/Deposit'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { debounce } from 'lodash'
import Button from '../../../components/Button/Button'
import { toast } from 'react-hot-toast'
import { formatUSDWithMask, formatCurrency } from '../../../utils/formatCurrency'
import { useAuth } from '../../../contexts/AuthContext'
import { fetchMerchantProcessors } from '../../../services/gatewayApi'
import gatewayApi from '../../../services/gatewayApi'

export default function AdminNewDeposit() {
  const navigate = useNavigate()
  const { merchantId, walletId } = useParams()
  const submitRef = useRef()

  const [open, setOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
  const [processorOptions, setProcessorOptions] = useState([])
  const [formValues, setFormValues] = useState({})
  const [merchantTransactionId, setMerchantTransactionId] = useState('')

  const { user } = useAuth()

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    const fetchProcessor = async () => {
      try {
        const response = await fetchMerchantProcessors({ queryKey: ['merchantProcessors', { merchantId }] })

        const options = response.processors.map((processor) => ({
          value: processor.processor_type + ' - ' + processor.id,
          id: processor.id
        }))

        setProcessorOptions(options)
      } catch (error) {
        console.error('Erro ao buscar os processadores:', error)
        toast.error('Erro ao carregar os provedores.')
      }
    }

    fetchProcessor().then(() => setOpen(true))
  }, [merchantId])

  const handleExecuteDeposit = useCallback(
    debounce(() => {
      try {
        setIsConfirmationLoading(true)
        const formattedAmount = formatCurrency(formValues.amount)

        const payload = {
          funding_id: crypto.randomUUID(),
          funding_amount: formattedAmount,
          merchant_transaction_id: merchantTransactionId == '' ? crypto.randomUUID() : merchantTransactionId,
          user_id: user.id,
          merchant_id: merchantId,
          processor_id: formValues.processor,
          first_name: 'Paag',
          last_name: '',
          email: 'z@paag.io',
          transaction_type: formValues.transaction_type
        }

        gatewayApi.post('/app/admin/transactions/fundings', payload).then((response) => {
          onCloseModal()
          setIsLoading(false)
          onClose()

          toast.success('Depósito realizado com sucesso!')
          setIsConfirmationLoading(false)
        })
      } catch (error) {
        toast.error('Erro na operação!')
        console.log('Erro na operação:', error)

        navigate(`/admin/merchant/${merchantId}/wallet`)
      }
    }, 500),
    [formValues, walletId]
  )

  const onError = (error) => console.log('Erro no formulário:', error)

  const onSubmit = (data) => {
    setIsLoading(true)
    setFormValues(data)

    setOpen(false)
    setIsProcessing(true)
  }

  const onCloseModal = () => {
    setIsProcessing(false)
    navigate(`/admin/merchant/${merchantId}/wallet`)
  }

  const onClose = () => {
    setOpen(false)

    if (!isProcessing) navigate(`/admin/merchant/${merchantId}/wallet`)
  }

  const generateMerchantTransactionId = () => {
    const uuid = crypto.randomUUID()
    setMerchantTransactionId(uuid)
  }

  return (
    <div>
      <SidePanel open={open} setOpen={setOpen} onClose={onClose}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title="Wallet Operation" />
          <SidePanelBody>
            <Form
              className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
              submitRef={submitRef}
              onSubmit={onSubmit}
              onError={onError}
            >
              <FieldSet
                name="processor"
                label="Provedor"
                rules={{ required: 'This field is required.' }}
                input={<SelectInput options={processorOptions} />}
                value={formValues.processor}
                onChange={(e) => setFormValues({ ...formValues, processor: e })}
              />

              <FieldSet
                name="transaction_type"
                label="Tipo de Transação"
                rules={{ required: 'This field is required.' }}
                input={<SelectInput options={TRANSACTION_TYPES} />}
                onChange={(e) => setFormValues({ ...formValues, transaction_type: e })}
              />

              <FieldSet
                name="amount"
                label="Valor"
                rules={{ required: 'This field is required.' }}
                input={
                  <TextInput
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-chill-500 focus:ring focus:ring-chill-500 focus:ring-opacity-50"
                    onChange={(e) => {
                      const formattedValue = formatUSDWithMask(e.target.value)
                      e.target.value = formattedValue
                    }}
                  />
                }
              />

              <FieldSet
                name="merchant_transaction_id"
                label="Merchant Transaction ID"
                rules={{ required: 'This field is required.' }}
                input={
                  <div className="space-y-1">
                    <div className="relative">
                      <TextInput
                        type="text"
                        value={merchantTransactionId}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-chill-500 focus:ring focus:ring-chill-500 focus:ring-opacity-50"
                        onChange={(e) => setMerchantTransactionId(e.target.value)}
                      />

                      <a
                        href="#"
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-chill-600 hover:underline cursor-pointer"
                        onClick={generateMerchantTransactionId}
                      >
                        Gerar UUID
                      </a>
                    </div>
                  </div>
                }
              />
            </Form>
          </SidePanelBody>

          <SidePanelFooter>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              Fechar
            </button>
            <button
              type="submit"
              className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2
                ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-chill-600 hover:bg-chill-700'}`}
              disabled={isLoading}
              onClick={() => submitRef.current.click()}
            >
              Processar
            </button>
          </SidePanelFooter>
        </div>
      </SidePanel>

      {isProcessing && (
        <ConfirmationModal
          title="Confirmação de Depósito"
          onClose={onCloseModal}
          confirmButton={
            <Button
              variant="primary"
              onClick={handleExecuteDeposit}
              disabled={isConfirmationLoading}
              isLoading={isConfirmationLoading}
            >
              Confirmar
            </Button>
          }
        >
          <p className="text-sm text-gray-500">Tem certeza que deseja realizar essa operação?</p>
        </ConfirmationModal>
      )}
    </div>
  )
}
