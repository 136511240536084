import { EyeIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { FormatDate, FormatTime } from '../../components/DateTime/FormatDate'
import DetailedList from '../../components/DetailedList/DetailedList'
import PixIcon from '../../components/Icons/PixIcon'
import SidePanel from '../../components/SidePanel/SidePanel'
import SidePanelBody from '../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../components/SidePanel/SidePanelHeader'
import Skeleton from '../../components/Skeleton/Skeleton'
import TransactionEventsTimeline from '../../components/TransactionEvents/TransactionEventsTimeline'
import { classNames } from '../../helpers/dom'
import gatewayApi from '../../services/gatewayApi'
import { TransactionAmount } from '../../components/TransactionHelpers'
import TransactionReceiptPdf from './TransactionReceiptPdf'
import TransactionStatus from '../../components/Transaction/TransactionStatus'
import { useTranslation } from 'react-i18next'
import { transactionTypesMap } from '../../data/TransactionTypes'
import { useQuery } from '@tanstack/react-query'
import { fetchUserMerchant } from '../../services/ledgerApi'
import { useAuth } from '../../contexts/AuthContext'
import { MS_50_MINUTES } from '../../helpers/msTimes'
import Button from '../../components/Button/Button'
import LoadingPuff from '../../components/Icons/LoadingPuff'
import clsx from 'clsx'
import ResendVoidButton from '../../components/ResendVoidButton/ResendVoidButton'

export default function Transaction() {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { transactionId } = useParams()
  const [isSendingWebhook, setIsSendingWebhook] = useState(false)
  const location = useLocation()
  const { user, isImpersonated } = useAuth()
  const impersonated = isImpersonated()
  const [activeTab, setActiveTab] = useState(() => {
    if (location.pathname.endsWith('receipt')) return 1

    return 0
  })
  const [data, setData] = useState({
    transaction: {
      statement: [],
      processor: {}
    }
  })
  const {
    data: merchantData,
    isLoading: merchantIsLoading,
    error: merchantError
  } = useQuery({
    queryFn: fetchUserMerchant,
    queryKey: ['fetchUserMerchant', { merchantId: user.merchant_id }],
    staleTime: MS_50_MINUTES,
    cacheTime: MS_50_MINUTES
  })

  const tabs = [
    {
      name: t('TransactionsSidePainelButtonDetails'),
      href: `/merchant/transactions/${data.transaction.id}`,
      current: true,
      icon: EyeIcon
    },
    {
      name: t('TransactionsSidePainelButtonReceipt'),
      href: `/merchant/transactions/${data.transaction.id}/receipt`,
      current: false,
      icon: ReceiptPercentIcon
    }
  ]

  const fetchData = () => {
    gatewayApi
      .get(`/app/merchant/transactions/${transactionId}?include[]=statement`, {
        params: {
          include: ['processor', 'transaction_events', 'statement']
        }
      })
      .then((r) => {
        setData(r.data)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log('erro em fetchData da requisção app/merchant/transactions. ', e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const reload = () => {
    setIsLoading(true)
    fetchData()
  }

  useEffect(() => {
    // Fetch user data from API
    fetchData()

    // Open the modal
    setOpen(true)
  }, [])

  const resendWebhook = async () => {
    setIsSendingWebhook(true)

    gatewayApi
      .post(`/app/merchant/transactions/${transactionId}/resend_webhook`)
      .then(() => {
        toast.success(t('TransactionsSidePainelTransactionResendWebhookSuccess'))
      })
      .catch(() => {
        toast.error(t('TransactionsSidePainelTransactionResendWebhookError'))
      })
      .finally(() => {
        setIsSendingWebhook(false)
      })
  }

  const showReceipt = !isLoading && !merchantIsLoading && !merchantError

  const showResendVoidButton =
    impersonated &&
    data.transaction.status === 'failed' &&
    data.transaction.transaction_events.some((event) => {
      if (event.event_type === 'void' && event.success === false && event.updated_at) {
        const updatedAt = new Date(event.updated_at)
        const now = new Date()
        const differenceInMinutes = (now - updatedAt) / 1000 / 60
        return differenceInMinutes >= 5
      }
      return false
    })

  return (
    <>
      <SidePanel open={open} setOpen={setOpen} onClose={() => navigate('/merchant/transactions')}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title={t('TransactionsSidePainelTitle')}>
            <div className="block">
              <div className=" border-chill-800 bg-chill-700">
                <nav className="-mb-px flex w-full" aria-label="Tabs">
                  {tabs.map((tab, i) => (
                    <Link
                      key={tab.name}
                      to={tab.href}
                      className={classNames(
                        activeTab == i
                          ? 'border-chill-800 bg-white text-chill-900'
                          : 'border-transparent text-chill-100 hover:border-chill-800 hover:text-chill-50',
                        'flex w-full items-center justify-center rounded-t-md px-1 py-4 text-center text-sm font-medium'
                      )}
                      aria-current={activeTab == i ? 'page' : undefined}
                      onClick={() => setActiveTab(i)}
                    >
                      <tab.icon
                        className={classNames(
                          activeTab == i ? 'text-chill-900' : 'text-chill-100 group-hover:text-chill-50',
                          '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </SidePanelHeader>
          <SidePanelBody>
            {activeTab == 0 ? (
              <div className="px-4 py-5 sm:p-0">
                <DetailedList
                  title={t('TransactionsSidePainelTransactionsDetails')}
                  button={
                    <Button
                      variant="link"
                      className={clsx('relative', { 'cursor-wait': isSendingWebhook })}
                      disabled={isSendingWebhook}
                      onClick={resendWebhook}
                    >
                      {isSendingWebhook && (
                        <LoadingPuff className="absolute right-full mx-auto h-16 stroke-chill-600" />
                      )}
                      {t('TransactionsSidePainelTransactionResendWebhookButton')}
                    </Button>
                  }
                >
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">ID</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? <Skeleton /> : data.transaction.id}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelFullName')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        `${data.transaction.first_name || 'N/A'} ${data.transaction.last_name || ''}`
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelEmail')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? <Skeleton /> : data.transaction.email ?? 'N/A'}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelCreationDate')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <>
                          <FormatDate date={data.transaction.created_at} locale="en-US" />
                          &nbsp;
                          <FormatTime date={data.transaction.created_at} locale="pt-BR" />
                        </>
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelProcessor')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <div className="flex items-center">
                          <span className="font-medium uppercase">{data.transaction?.processor?.processor_type}</span>
                          <span className="ml-2 text-xs text-gray-500">{data.transaction?.processor?.alias}</span>
                        </div>
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelAmount')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? <Skeleton /> : <TransactionAmount transaction={data.transaction} align="left" />}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelPaymentType')}</dt>
                    <dd className="mt-1 text-sm text-gray-800 sm:col-span-2 sm:mt-0">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <div className="flex items-center ">
                          {React.createElement(transactionTypesMap[data.transaction.transaction_type].icon, {
                            className: 'fill-gray-600',
                            height: 20
                          })}
                          <span className="ml-2 font-medium">
                            {t(transactionTypesMap[data.transaction.transaction_type].label)}
                          </span>{' '}
                        </div>
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelStatus')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {isLoading ? <Skeleton /> : <TransactionStatus transaction={data.transaction} />}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">{t('TransactionsSidePainelEvents')}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="flow-root">
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <TransactionEventsTimeline transactionEvents={data.transaction.transaction_events} />
                        )}
                      </div>
                      {showResendVoidButton && (
                        <div className="mt-4">
                          <ResendVoidButton transactionId={data.transaction.id} onDone={reload} />
                        </div>
                      )}
                    </dd>
                  </div>
                </DetailedList>
              </div>
            ) : (
              <div>
                <Outlet context={{ transaction: data.transaction, merchant: merchantData, isLoading }} />
              </div>
            )}
          </SidePanelBody>
          <SidePanelFooter>
            {/* {showReceipt && <TransactionReceiptPdf transaction={data.transaction} merchant={merchantData} />} */}
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              {t('TransactionsSidePainelButtonClose')}
            </button>
          </SidePanelFooter>
        </div>
      </SidePanel>
    </>
  )
}
