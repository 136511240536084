import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SignIn from '../pages/Auth/SignIn'
import SignUp from '../pages/Auth/SignUp'
import AuthLayout from '../layouts/AuthLayout'
import ProtectedLayout from '../layouts/ProtectedLayout'
import SignOut from '../pages/Auth/SignOut'
import ResetPassword from '../pages/Auth/ResetPassword'
import NotFound from '../pages/Error/NotFound'
import AdminRouter from './AdminRouter'
import MerchantRouter from './MerchantRouter'
import Confirm from '../pages/Auth/Confirm'
import CustomsRouter from './CustomsRouter'
import SupportRouter from './SupportRouter'

export default function Router() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="confirm" element={<Confirm />} />
      </Route>

      <Route path="/" element={<ProtectedLayout />}>
        {MerchantRouter()}
        {AdminRouter()}
        {CustomsRouter()}
        {SupportRouter()}
        <Route path="/auth/sign-out" element={<SignOut />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
