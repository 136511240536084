export const discoverField = (value) => {
  const e2eReg = new RegExp('(e|E)\\d.{30}')
  const cpfReg = new RegExp('^\\d{11}|\\d{3}\\.\\d{3}\\.\\d{3}\\-\\d{2}')
  const emailReg = new RegExp('.*@.*\\..{2,}')

  if (value.match(e2eReg)) {
    return 'e2e'
  }

  if (value.match(emailReg)) {
    return 'email'
  }

  if (value.match(cpfReg)) {
    return 'document'
  }

  return 'name'
}
