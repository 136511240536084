import React, { useMemo, useEffect, useState, useTransition } from 'react'
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { FormatDate, FormatTime } from '../../../components/DateTime/FormatDate'
import PixIcon from '../../../components/Icons/PixIcon'
import PageHeader from '../../../components/Pages/PageHeader'
import Table from '../../../components/Table/Table'
import { TransactionAmount, TransactionHash } from '../../../components/TransactionHelpers'
import TransactionStatus from '../../../components/Transaction/TransactionStatus'
import { transactionTypesMap } from '../../../data/TransactionTypes'
import Filters from '../../../components/Filters/Filters'
import { useAuth } from '../../../contexts/AuthContext'
import Main from '../../../components/Main/Main'
import SelectInput from '../../../components/SelectInput/SelectInput'
import ledgerApi from '../../../services/ledgerApi'
import { getTransactionsByMerchantId, fetchTransactionsList } from '../../../services/gatewayApi'
import { discoverField } from '../../../utils/fieldUtils'
import Badge from '../../../components/Badge/Badge'

const fetchMerchants = async () => {
  try {
    const response = await ledgerApi.get('/v1/admin/merchants', { params: { q: 'is_regulated' } })
    return response.data
  } catch (error) {
    console.error('Erro ao buscar merchants:', error)
    throw error
  }
}
const initialFilters = {
  type: ['charge', 'payment'],
  status: [],
  processor: [],
  source: ['settlement', 'funding']
}

export default function ListPaymentTransfers() {
  const location = useLocation()
  const auth = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedMerchant, setSelectedMerchant] = useState()
  const page = searchParams.get('page') || 1
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(page)
  const [searchQueryValues, setSearchQueryValues] = useState({
    name: '',
    e2e_id: '',
    document: '',
    email: ''
  })

  const fetchMerchantTransactions = async ({ queryKey }) => {
    const [, { merchant_id, currentPage }] = queryKey
    const response = await getTransactionsByMerchantId({
      queryKey: ['transactions', { merchant_id: merchant_id, currentPage }],
      signal: null,
      meta: { filters: normalizedFilters }
    })
    return response
  }

  const [isFetchingMerchants, startFetchMerchantTransition] = useTransition()
  const [merchants, setMerchants] = useState([])

  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const queryClient = useQueryClient()
  const searchQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''
  const normalizedFilters = useMemo(
    () => ({
      ...searchQueryValues,
      ...selectedFilters
    }),
    [searchQueryValues, selectedFilters]
  )

  const filters = [
    {
      id: 'status',
      title: t('TransactionsSidePainelStatus'),
      options: [
        { id: 'success', title: t('TransactionsFiltersTypeSuccess') },
        { id: 'failed', title: t('TransactionsFiltersTypeFailed') },
        { id: 'pending', title: t('TransactionsFiltersTypePending') }
      ]
    },
    {
      id: 'processor',
      title: t('DashboardProcessors'),
      options: [
        { id: 'bs2', title: 'bs2' },
        { id: 'celcoin', title: 'celcoin' }
      ]
    }
  ]

  const { isLoading, data, isRefetching } = useQuery({
    queryKey: ['listTransactions', { merchant_id: selectedMerchant, currentPage }],
    queryFn: fetchMerchantTransactions,
    enabled: !!selectedMerchant,
    keepPreviousData: true
  })

  useEffect(() => {
    startFetchMerchantTransition(async () => {
      try {
        const data = await fetchMerchants()
        setMerchants(data)
      } catch (e) {
        toast.error(t('FinancialMerchant.alertFetchMerchantsError'))
      }
    })
  }, [])

  useEffect(() => {
    if (location.pathname === '/admin/ListPaymentTransfers' && !!page) {
      setCurrentPage(page)
    }
  }, [page, location.pathname])

  useEffect(() => {
    if (data) {
      setTransactions(data)
    }
  }, [data])

  useEffect(() => {
    ;(async () => {
      setIsFilterLoading(true)
      await queryClient.cancelQueries()
      await queryClient.fetchQuery({
        queryKey: ['listTransactions', { merchant_id: selectedMerchant, currentPage }],
        queryFn: fetchMerchantTransactions
      })
      setIsFilterLoading(false)
    })()
  }, [normalizedFilters, currentPage, selectedMerchant])

  const handleFilterChange = async (filters) => {
    await queryClient.cancelQueries()
    setCurrentPage(1)
    setSelectedFilters(filters)
  }

  const handleSearchQueryChange = (event) => {
    let value = event.target.value
    const field = discoverField(value)
    if (field === 'e2e_id') {
      value = value.toLowerCase()
    }

    const textValues = {
      e2e_id: '',
      name: '',
      document: '',
      email: '',
      [field]: value
    }

    setSearchQueryValues({ ...textValues })
  }

  const handleMerchantChange = (value) => {
    setSelectedMerchant(value)
    setCurrentPage(page)
    setSearchParams({ page: 1 })
  }

  const handleResetFilters = () => {
    setSelectedFilters(initialFilters)
    setSearchQueryValues({ name: '', e2e_id: '', document: '', email: '' })
  }

  const processorColors = {
    BS2: 'blue',
    CELCOIN: 'yellow',
    STARK: 'gray'
  }

  const columns = [
    {
      title: t('TransactionsTableStatus'),
      headerClassName: 'text-center',
      render: (transaction) => <TransactionStatus transaction={transaction} size="small" />
    },
    {
      title: t('DashboardProcessors'),
      headerClassName: 'text-center',
      render: (transaction) => {
        const processorType = transaction.processor?.processor_type?.toUpperCase()
        const processorColor = processorColors[processorType] || 'gray'

        return (
          <div className="flex justify-center">
            <Badge variant={processorColor}>{processorType}</Badge>
          </div>
        )
      }
    },
    {
      title: t('TransactionsTableTransaction'),
      headerClassName: 'text-left',
      render: (transaction) => (
        <div className="text-gray-500">
          <div className="flex items-center sm:hidden">
            <div>
              <PixIcon className="h-5 fill-sky-600 stroke-2" />
            </div>
            <div className="ml-2">
              <span className="block font-medium text-gray-800">{transaction.id}</span>
              <span className="text-xs">
                <FormatDate date={transaction.created_at} locale="en-US" />
                &nbsp;
                <FormatTime date={transaction.created_at} locale="pt-BR" />
              </span>
            </div>
          </div>
          <div className="hidden font-semibold sm:block">{transaction.id}</div>
        </div>
      )
    },
    {
      title: t('TransactionsTableClient'),
      render: ({ first_name, last_name, email }) => (
        <>
          <span className="block font-semibold text-gray-700">{`${first_name || 'N/A'} ${last_name || ''}`}</span>
          <span className="text-xs">{email}</span>
        </>
      )
    },
    {
      title: t('TransactionsTableDate'),
      onlyDesktop: true,
      render: ({ created_at }) => (
        <>
          <div className="text-sm font-medium text-gray-900">
            <FormatDate date={created_at} locale="en-US" />
          </div>
          <div className="text-xs text-gray-500">
            <FormatTime date={created_at} locale="pt-BR" />
          </div>
        </>
      )
    },
    {
      title: t('TransactionsTableAmount'),
      headerClassName: 'text-right',
      rowClassName: 'text-right',
      render: (transaction) => <TransactionAmount transaction={transaction} />
    }
  ]

  const currentTab = useMemo(() => {
    if (selectedFilters.type.includes('charge') && selectedFilters.type.includes('payment')) {
      return 'all'
    }

    if (selectedFilters.type.includes('charge')) {
      return 'charge'
    }

    return 'payment'
  }, [selectedFilters])

  return (
    <div>
      <Outlet />

      <PageHeader title={t('PaymentTransfersTitle')} subtitle={t('PaymentTransfersSubtitle')} />

      <Main>
        <div className="text-left mt-4 w-96 mb-4">
          <SelectInput
            placeholder={t('placeholderSelectMerchant')}
            options={merchants.map((merchant) => ({
              value: merchant.id,
              label: merchant.name
            }))}
            value={selectedMerchant}
            isLoading={isFetchingMerchants}
            onChange={handleMerchantChange}
          />
        </div>
      </Main>

      <Filters
        filters={filters}
        selectedFilters={selectedFilters}
        onChangeFilters={handleFilterChange}
        onChangeSearchQuery={handleSearchQueryChange}
        searchQueryPlaceholder={t('TransactionsInputSearch')}
        onReset={handleResetFilters}
      />

      <Main>
        <Table
          isRefetching={isRefetching}
          columns={columns}
          isLoading={isLoading || isFilterLoading}
          rows={transactions?.transactions || []}
          pagination={transactions?.meta || []}
          paginationBasePath="/admin/ListPaymentTransfers"
          hasFilters
        />
      </Main>
    </div>
  )
}
