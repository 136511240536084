import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import authenticatorApi from '../../../services/authenticatorApi'
import UserForm from '../../Users/UserForm'
import Button from '../../../components/Button/Button'

export default function AdminNewUser() {
  const navigate = useNavigate()
  const { merchantId } = useParams()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const submitRef = useRef()

  const onSubmit = async (data) => {
    setIsLoading(true)
    await authenticatorApi.post('/app/admin/users', { user: data })
    setIsLoading(false)
    setOpen(false)
  }

  const onError = (error) => console.log('error', error)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <div>
      <SidePanel open={open} setOpen={setOpen} onClose={() => navigate(`/admin/merchant/${merchantId}/users`)}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title="Create User" onError={onError} />
          <SidePanelBody>
            <UserForm submitRef={submitRef} merchantId={merchantId} onSubmit={onSubmit} />
          </SidePanelBody>
          <SidePanelFooter>
            <Button type="button" variant="simple" disabled={isLoading} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" loading={isLoading} onClick={() => submitRef.current.click()}>
              Save
            </Button>
          </SidePanelFooter>
        </div>
      </SidePanel>
    </div>
  )
}
